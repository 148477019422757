import React from 'react'
import { connect } from 'react-redux'
import { format, differenceInMinutes } from 'date-fns'
import classNames from 'classnames'
import calculateFormattedDateTime from 'utils/calculateFormattedDateTime'

const TalksMessage = ({ chat, user, recordedCall, onRecordedCallClick }) => {
  const talksMessageClass = classNames('talks_message', {
    talks_message_no_record_state: !recordedCall,
  })

  const minDifference = differenceInMinutes(
    new Date(chat?.finished_at),
    new Date(chat?.started_at)
  )

  const differenceVal = minDifference === 0 ? '<1' : minDifference

  const parsedParticipants = chat.attendes
    ?.split(',')
    .filter((e) => e !== ' ' && e !== '').length

  const participants = parsedParticipants >= 2 ? ` ${parsedParticipants} attended` : ``

  const handleClick = async () => {
    if (!recordedCall && !onRecordedCallClick) return

    onRecordedCallClick(recordedCall?.video_link)
  }

  const attendes = chat.attendes
    ?.split(',')
    .filter((email) => email !== chat.pro.user.email)

  const getFormattedDate = (dateString) => {
    const now = new Date()
    const date = new Date(dateString)
    const diffInMilliseconds = now - date
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60)
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)

    if (diffInHours < 24) {
      const hours = Math.floor(diffInHours)
      const minutes = Math.floor((diffInHours - hours) * 60)
      return `${hours}:${minutes.toString().padStart(2, '0')} ago`
    } else if (diffInDays < 7) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return daysOfWeek[date.getUTCDay()]
    } else {
      const month = date.getUTCMonth() + 1
      const day = date.getUTCDate()
      const year = date.getUTCFullYear()
      return `${month}/${day}/${year}`
    }
  }

  return (
    <button onClick={handleClick}>
      <div className={talksMessageClass}>
        <div className="talks_message_user_photo">
          {(chat.cover_image || chat?.client?.photo) && (
            <img
              className="talks_message_user_photo_in"
              src={
                chat.title
                  ? chat?.cover_image
                    ? chat?.cover_image
                    : ''
                  : chat.client?.photo
              }
              alt={chat.title ? chat.title : chat.client?.user?.name}
            />
          )}
        </div>
        <div className="talks_message_descr">
          <h3 className="talks_message_user_name">
            {attendes?.map((item) => {
              if (item.length) {
                return <div class="talks_message_user_name__item">{item}</div>
              }
            })}
            {attendes?.length > 3 && (
              <div class="talks_message_user_name__more">+{attendes.length - 3}</div>
            )}
          </h3>
          <div className="talks_message_date">
            {user?.user?.email === chat.client?.user?.email
              ? chat.pro?.user?.name
              : chat.client?.user?.name}
            {chat.title && chat.title}
          </div>
        </div>
        <div className="talks_message_date">
          {getFormattedDate(chat?.scheduled_at)}
          {/* {format(new Date(chat?.scheduled_at), 'M/d/yy')}&nbsp; */}
          {/* {format(new Date(chat?.started_at), 'h:mm a')} */}
          {/* &nbsp; */}
          {/* {chat.title ? participants : `${differenceVal}min`} */}
        </div>
      </div>
    </button>
  )
}

const mapStateToProps = ({ auth: { user, isPro } }) => ({
  user,
  isPro,
})

export default connect(mapStateToProps)(TalksMessage)

import React, { useContext, useState, useEffect, useRef } from 'react'
import { UiContext } from 'context/ui'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { useClickOutside } from 'hooks'
import { isPast, format, addYears } from 'date-fns'
import { callApi, eventApi } from 'api'

import { Spinner } from 'components/Spinner'
import styles from './BillingPopup.module.scss'
import Popup from '../Popup/Popup'
import { PROMISE_STATES } from 'utils/constants'

const BillingPopup = ({ isOpen, isPro, user, loading }) => {
  const $container = useRef()
  const { setActivePopupId } = useContext(UiContext)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [calls, setCalls] = useState()
  const [events, setEvents] = useState()
  const [callsLoading, setCallsLoading] = useState(true)
  const [callsAndEvents, setCallsAndEvents] = useState()
  {
    /* const popupHead = ['Date', 'Time', 'Attendee(s)', 'Amount', 'Fees', 'Net'] */
  }
  const popupHead = ['Date', 'Time', 'Title/Amount', 'User/Attendee(s)']

  const handleClose = () => {
    setActivePopupId(null)
  }

  useClickOutside($container, handleClose)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    setCallsAndEvents(true)

    const filterCalls = (calls) => {
      return calls?.filter((e) => isPast(new Date(e?.scheduled_at)) && e?.finished_at)
    }

    if (loading === PROMISE_STATES.fulfilled) {
      if (isPro) {
        callApi.getCalls().then((res) => setCalls(filterCalls(res?.result)))
      }

      if (isPro === false) {
        callApi.getUserCalls().then((res) => setCalls(filterCalls(res?.result)))
      }
    }

    window.addEventListener('resize', handleResize)
  }, [isPro, loading])

  useEffect(() => {
    if (calls && events) {
      setCallsAndEvents(
        [...calls, ...events].sort(
          (a, b) => new Date(b.scheduled_at) - new Date(a.scheduled_at)
        )
      )
    }

    if (calls && events) {
      setCallsLoading(false)
    }
  }, [calls, events])

  return (
    <Popup
      isOpen={isOpen}
      type="v2"
      title="History"
      description="Choose which days and from what time you would like to offer 1on1 calls. "
      centerContent
    >
      {user && (
        <>
          <div className={styles.billing}>
            <div className={styles.billingHead}>
              <div className={styles.billingCol}>
                <div className={styles.billingTitle}>Anual Fee</div>
                <div className={styles.billingValue}>
                  {' '}
                  ${`${Math.abs(parseInt(user.subscription_balance) - 99)}`} of $99 paid
                </div>
              </div>
              <div className={styles.billingCol}>
                <div className={styles.billingTitle}>Renewal date</div>
                <div className={styles.billingValue}>
                  {' '}
                  {format(
                    addYears(new Date(user.subscription_timestamp), 1),
                    'MMMM dd, yyyy'
                  )}
                </div>
              </div>
            </div>
          </div>
          {callsLoading ? (
            <Spinner style={{ position: 'relative', top: '6rem' }} />
          ) : (
            callsAndEvents?.length === 0 && (
              <div className="requests_empty_placeholder">No Prior talks</div>
            )
          )}
          {callsAndEvents && callsAndEvents.length > 0 && (
            <div className={styles.tableWrap}>
              <div className={styles.table}>
                <div className={styles.tableHead}>
                  {popupHead &&
                    popupHead.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classNames(styles.tableItem, styles.tableItemV2)}
                        >
                          {item}
                        </div>
                      )
                    })}
                </div>
                <div className={styles.tableBody}>
                  {callsAndEvents?.map((e, i) => {
                    if (e.total_cost == 0 || parseInt(e.entry_fee) == 0) {
                    } else {
                      return (
                        <div className={styles.tableRow} key={i}>
                          <div className={styles.tableItem}>
                            {format(new Date(e?.scheduled_at), 'M/d/yy').toString()}
                          </div>
                          <div className={styles.tableItem}>
                            {' '}
                            {e.started_at && e.finished_at
                              ? `${format(new Date(e.started_at), 'p')} to ${' '}
                              ${format(new Date(e.finished_at), 'p')}`
                              : `${format(new Date(e.scheduled_at), 'p')}`}
                          </div>
                          <div className={styles.tableItem}>
                            {e.title}{' '}
                            <span class="cost">
                              (${e.total_cost || parseInt(e.entry_fee).toFixed(0)})
                            </span>
                          </div>
                          <div className={styles.tableItem}>
                            {e.attendes &&
                              `${
                                e.attendes
                                  ?.split(',')
                                  .filter((e) => e !== ' ' && e !== '').length
                              } / ${e.max_participants} attended`}
                          </div>

                          {/* <div className={styles.tableItem}>
                          {' '}
                          {isPro ? e.client?.user?.name : e.pro?.user?.name}
                          {e.attendes &&
                            `${
                              e.attendes?.split(',').filter((e) => e !== ' ' && e !== '')
                                .length
                            } / ${e.max_participants} attendees`}
                        </div>
                        <div className={styles.tableItem}>
                          ${e.total_cost || parseInt(e.entry_fee).toFixed(0)}
                        </div>
                        <div className={styles.tableItem}>
                          ${e.platform_fee || (parseInt(e.entry_fee) * 0.03).toFixed(2)}
                        </div>
                        <div className={styles.tableItem}>
                          ${e.pro_profit || (parseInt(e.entry_fee) * 0.97).toFixed(2)}
                        </div> */}
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Popup>
  )
}

const mapStateToProps = ({ auth: { isPro, user, loading } }) => ({
  isPro,
  user,
  loading,
})

export default connect(mapStateToProps)(BillingPopup)

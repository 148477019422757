import { createCheckout, captureOrder, captureEntry } from './User'

import {
  connectStripe,
  onboardSeller,
  setProRate,
  createSubscription,
  registerSubscription,
} from './Pro'

export const paymentApi = {
  setProRate,
  connectStripe,
  onboardSeller,
  createCheckout,
  captureOrder,
  captureEntry,
  createSubscription,
  registerSubscription,
}

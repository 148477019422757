import React from 'react'

import styles from './DashboardChatBtn.module.scss'

const DashboardChatBtn = ({ children, handleClick }) => {
  return (
    <button className={styles.btn} onClick={handleClick} type="button">
      {children}
    </button>
  )
}

export default DashboardChatBtn

import { DASHBOARD_CONTENT_STATES, POPUPS_ID } from 'utils/constants'
import PAGE_NAMES from './page-names'

const MENU_DATA = [
  {
    title: PAGE_NAMES.schedule,
    url: '/dashboard/pro',
    dashboardContent: DASHBOARD_CONTENT_STATES.default,
  },
  {
    title: PAGE_NAMES.availability,
    url: '',
    popupId: POPUPS_ID.availability,
  },
  {
    title: PAGE_NAMES.classes,
    url: '',
    popupId: POPUPS_ID.classes,
  },
  {
    title: PAGE_NAMES.videoes,
    url: '',
    popupId: POPUPS_ID.addVideo,
  },
  {
    title: PAGE_NAMES.profile,
    url: '',
    popupId: POPUPS_ID.profile,
  },
  {
    title: PAGE_NAMES.billing,
    url: '',
    popupId: POPUPS_ID.billing,
  },
  {
    title: PAGE_NAMES.help,
    url: '',
    popupId: POPUPS_ID.help,
  },
]

export default MENU_DATA

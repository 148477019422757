import React from 'react'
import { cva } from 'class-variance-authority'

import { ReactComponent as GoogleIcon } from 'i/google.svg'
import { ReactComponent as AppleIcon } from 'i/apple.svg'

import styles from './Button.module.scss'

const buttonStyles = cva(styles.button, {
  variants: {
    variant: {
      primary: styles.buttonPrimary,
      secondary: styles.buttonSecondary,
      default: styles.buttonDefault,
      dark: styles.buttonDark,
      light: styles.buttonLight,
      success: styles.buttonSuccess,
      outlined: styles.buttonOutlined,
    },
    size: {
      sm: styles.buttonSmSize,
      md: styles.buttonMdSize,
      base: styles.buttonBaseSize,
    },
    rounded: {
      sm: styles.buttonRoundedSm,
    },
    fullWidth: {
      true: styles.buttonFullWidth,
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'base',
  },
})

const Button = ({
  children,
  type = 'button',
  iconName,
  size,
  variant,
  disabled = false,
  fullWidth,
  rounded,
  onClick = () => {},
  ...props
}) => {
  const renderIcon = () => {
    if (iconName === 'google') {
      return <GoogleIcon className={styles.iconImage} />
    }

    if (iconName === 'apple') {
      return <AppleIcon className={styles.iconImage} />
    }
  }

  return (
    <button
      className={buttonStyles({ variant, size, fullWidth, rounded })}
      type={type}
      disabled={disabled}
      {...props}
      onClick={onClick}
    >
      {iconName && <span className={styles.icon}>{renderIcon()}</span>}
      {children}
    </button>
  )
}

export default Button

import React from 'react'
import Select, { components } from 'react-select'
import classNames from 'classnames'

import { ReactComponent as GlobeIcon } from 'i/icons/globe_3.svg'
import { ReactComponent as ClockIcon } from 'i/icons/clock.svg'

import styles from './FormCustomSelect.module.scss'

const CustomSelectControl = (props) => {
  return (
    <components.Control {...props}>
      <div className={styles.controller}>
        <div className={styles.controllerIcon}>
          <GlobeIcon />
        </div>
        {props.children}
      </div>
    </components.Control>
  )
}

const CustomSelectControl2 = (props) => {
  return (
    <components.Control {...props}>
      <div className={styles.timepicker}>
        <div className={styles.timepickerIcon}>
          <ClockIcon />
        </div>
        {props.children}
      </div>
    </components.Control>
  )
}

const CustomSelectControl3 = (props) => {
  return (
    <components.Control {...props}>
      <div className={styles.defaultSelect}>{props.children}</div>
    </components.Control>
  )
}

const FormCustomSelect = ({ options, onChange, v2, v3, placeholder }) => {
  return (
    <div
      className={classNames(styles.select, {
        [styles.selectV2]: v2,
        [styles.selectV3]: v3,
      })}
    >
      <Select
        components={{
          Control: v2
            ? CustomSelectControl2
            : v3
            ? CustomSelectControl3
            : CustomSelectControl,
        }}
        className="select__in"
        classNamePrefix="select"
        isSearchable={false}
        options={options}
        onChange={onChange}
        defaultValue={v2 || v3 ? null : options[0]}
        placeholder={placeholder || null}
      />
    </div>
  )
}

export default FormCustomSelect

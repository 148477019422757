import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { UiContext } from 'context/ui'

import { ReactComponent as BackIcon } from 'i/icons/back.svg'
import { ReactComponent as CloseIcon } from 'i/icons/close.svg'
import { ReactComponent as SearchIcon } from 'i/icons/search.svg'

import styles from './DashboardSearch.module.scss'
import DashboardPlayVideoBtn from '../DashboardChat/ui/DashboardChatBtn/DashboardChatBtn'
import { POPUPS_ID } from 'utils/constants'

const DashboardSearch = ({
  isOpen,
  setChat,
  chats,
  transcriptions,
  setIsSearchActive,
}) => {
  const { setActivePopupId } = useContext(UiContext)
  const [inputValue, setInputValue] = useState('')
  const [filteredTexts, setFilteredTexts] = useState([])
  const [filteredNotes, setFilteredNotes] = useState([])

  const filterTextByInput = (data, inputString) => {
    const filteredTexts = []
    const filteredNotes = []

    const regex = new RegExp(`(${inputString})`, 'gi')

    for (const item of chats) {
      const words = item.meeting_notes.split(' ')
      const index = words.findIndex((word) =>
        word.toLowerCase().includes(inputString.toLowerCase())
      )

      if (index !== -1) {
        const start = Math.max(0, index - 5)
        const end = Math.min(words.length, index + 6)

        const beforeMatch = words.slice(start, index).join(' ')
        const match = words[index]
        const afterMatch = words
          .slice(index + 1, end)
          .join(' ')
          .replace(regex, '<strong>$1</strong>')

        const highlightedMatch = match
          .split('')
          .map((char, i) => {
            return inputString.toLowerCase().includes(char.toLowerCase())
              ? `<strong>${char}</strong>`
              : char
          })
          .join('')

        filteredNotes.push({
          text: `${beforeMatch} ${highlightedMatch} ${afterMatch}`,
          id: item.id,
          name: item.title,
          isNotes: true,
        })
      }
    }

    setFilteredNotes(filteredNotes)

    for (const item of data) {
      const filteredTranscription = item.transcription.filter((entry) =>
        entry?.text?.toLowerCase().includes(inputString.toLowerCase())
      )

      filteredTranscription.forEach((filteredEntry) => {
        const highlightedText = filteredEntry.text.replace(regex, '<strong>$1</strong>')

        filteredTexts.push({ ...filteredEntry, text: highlightedText, id: item.id })
      })
    }

    setFilteredTexts(filteredTexts)
  }

  const closePopup = () => {
    setActivePopupId(null)
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
    filterTextByInput(transcriptions, event.target.value)
  }

  useEffect(() => {
    if (inputValue.length > 0) {
      setIsSearchActive(true)
    } else {
      setIsSearchActive(false)
    }
  }, [inputValue])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 500,
        exit: 350,
      }}
      mountOnEnter
      unmountOnExit
    >
      <>
        <div
          className={classNames(styles.DashboardSearch, {
            [styles.DashboardSearchActive]: inputValue.length > 0,
          })}
        >
          <div className={styles.DashboardSearchHead}>
            {/* <div className={styles.DashboardSearchBack}>
              <BackIcon className={styles.DashboardSearchBackIcon} onClick={closePopup} />
            </div> */}
            <button
              type="submit"
              className={styles.DashboardSearchClose}
              onClick={closePopup}
            >
              <SearchIcon className={styles.DashboardSearchCloseIcon} />
            </button>
            <form className={styles.DashboardSearchForm}>
              <input
                type="text"
                placeholder="Search transcripts"
                className={styles.DashboardSearchInput}
                onChange={handleInputChange}
                value={inputValue}
                autoFocus
              />
            </form>
            {/* <button
              type="button"
              className={styles.DashboardSearchClose}
              onClick={closePopup}
            >
              <CloseIcon className={styles.DashboardSearchCloseIcon} />
            </button> */}
          </div>
          {inputValue.length > 0 && (
            <div className={styles.DashboardSearchMain}>
              <div className={styles.DashboardSearchMainIn}>
                {filteredTexts &&
                  filteredNotes &&
                  [...filteredTexts, ...filteredNotes].map((item, index) => {
                    return (
                      <div
                        className={styles.DashboardSearchItem}
                        key={index}
                        onClick={(e) => {
                          e.preventDefault()
                          let chat = chats.find((chat) => chat.id === item.id)
                          setChat(chat)
                          setActivePopupId(POPUPS_ID.chat)
                        }}
                      >
                        <div className={styles.DashboardSearchItemTitle}>{item.name}</div>
                        <div
                          className={styles.DashboardSearchItemText}
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></div>
                      </div>
                    )
                  })}
              </div>
              {/* <div className={styles.DashboardSearchMainBtnWrap}>
                <DashboardPlayVideoBtn title="Play recoding" />
              </div> */}
            </div>
          )}
        </div>
      </>
    </CSSTransition>
  )
}

export default DashboardSearch

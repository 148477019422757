import React, { useState } from 'react'
import classNames from 'classnames'
import { SummaryPlayer } from './ui/SummaryPlayer/SummaryPlayer'
import { SummaryNotes } from './ui/SummaryNotes/SummaryNotes'
import { LogoutButton } from 'components/LogoutButton'
import { ReactComponent as Logo } from 'i/logo.svg'
import styles from './SummaryPage.module.scss'
import { SummaryTranscription } from './ui/SummaryTranscription/SummaryTranscription'
import { Spinner } from 'components/Spinner'
import { useParams } from 'react-router-dom'
import { callApi } from 'api'
import { PROMISE_STATES } from 'utils/constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import getCallType from 'utils/getCallType'
import calculateFormattedDateTime from 'utils/calculateFormattedDateTime'
import useFetchWithRetry from 'hooks/useFetchWithRetry'

const TABS = {
  nav: [
    {
      tabName: 'notes',
      title: 'Meeting notes',
    },
    {
      tabName: 'transcript',
      title: 'Transcript',
    },
    {
      tabName: 'video',
      title: 'Video',
    },
  ],
}

const SummaryPage = (props) => {
  const [activeTab, setActiveTab] = useState(TABS.nav[0].tabName)
  const [errorText, setErrorText] = useState(null)
  const [successText, setSuccessText] = useState(null)
  const [sendingMessageStatus, setSendingMessageStatus] = useState(
    PROMISE_STATES.fulfilled
  )
  const { id } = useParams()
  const callType = getCallType()

  const { loading, error, data } = useFetchWithRetry(() =>
    callApi.getCallSummary(callType, id)
  )

  const history = useHistory()

  if (loading === PROMISE_STATES.rejected && error) {
    if (props.isPro) {
      history.push('/dashboard/pro')
    } else {
      history.push('/dashboard/user')
    }
  }

  const shareHandler = async () => {
    setSendingMessageStatus(PROMISE_STATES.pending)
    await callApi.shareMessage(callType, id).then((res) => {
      if (res.error) {
        setErrorText(res.error.data.detail)
      } else {
        setErrorText(null)
        setSuccessText('Shared successfully!')
        setTimeout(() => {
          setSuccessText(null)
        }, 1000)
      }
      setSendingMessageStatus(PROMISE_STATES.fulfilled)
    })
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const localTimeResult = calculateFormattedDateTime(
    data?.result.data.scheduledAt,
    'Etc/Greenwich',
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  return (
    <section className={classNames('section', styles.section)}>
      <a href="/dashboard/pro" className={styles.back}>
        {'< Back'}
      </a>
      {loading === PROMISE_STATES.pending && !data?.result?.data?.meetingNotes ? (
        <div className={styles.spinnerContainer}>
          <Spinner className="spinner_button" />
          <div className={styles.spinnerNote}>
            Your AI assistant is preparing summary notes
          </div>
        </div>
      ) : null}
      {loading === PROMISE_STATES.fulfilled && data?.result?.data?.meetingNotes ? (
        <>
          <LogoutButton className={styles.logout} text="Logout" />
          <div className={styles.head}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.title}>Events Platform Review</div>
            {data?.result?.data?.scheduledAt && (
              <div className={styles.date}>{localTimeResult}</div>
            )}
            {props.isPro && (
              <>
                <button
                  className={classNames(styles.share, 'btn_v10')}
                  type="button"
                  onClick={shareHandler}
                >
                  {sendingMessageStatus === PROMISE_STATES.pending && (
                    <Spinner className={styles.spinner} />
                  )}
                  {sendingMessageStatus === PROMISE_STATES.fulfilled &&
                    'Share with attendees'}
                </button>

                {errorText && <div className={styles.error}>{errorText}</div>}
                {successText && <div className={styles.success}>{successText}</div>}
              </>
            )}
          </div>

          <div className={styles.tabs}>
            <div className={styles.nav}>
              {TABS.nav.map(({ title, tabName }) => (
                <button
                  key={tabName}
                  type="button"
                  className={classNames(styles.button, {
                    [styles.active]: tabName === activeTab,
                  })}
                  onClick={() => handleTabClick(tabName)}
                >
                  {title}
                </button>
              ))}
            </div>
            <div className={styles.body}>
              {activeTab === 'notes' && (
                <SummaryNotes
                  notes={data?.result?.data?.meetingNotes}
                  callType={callType}
                  callId={id}
                  isChangeable={props.isPro}
                />
              )}
              {activeTab === 'transcript' && <SummaryTranscription />}
              {activeTab === 'video' && <SummaryPlayer roomID={id} />}
            </div>
          </div>
        </>
      ) : null}
    </section>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user } }) => ({
  authError: error,
  user,
  isPro,
})

export default connect(mapStateToProps)(SummaryPage)

import { React, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isFuture, isPast } from 'date-fns'
import { useWindowSize } from 'hooks'
import { CSSTransition } from 'react-transition-group'
import { UiContext } from 'context/ui'

import {
  DashboardAccount,
  DashboardReminder,
  DashboardSidebar,
  DashboardRecentCalls,
  DashboardHeader,
} from 'components/Dashboard'
import { MeetingsAndCalls } from 'components/Dashboard/MeetingsAndCalls'
import { Popup, AddVideoPopup, SendInvitePopup, ClassesPopup } from 'components/Popups'
import { CreateClassForm } from 'components/CreateClassForm'
import { AvailabilityForm } from 'components/AvailabilityForm'
import MeetingInfoPopup from 'components/Popups/MeetingInfoPopup/MeetingInfoPopup'
import HelpPopup from 'components/Popups/HelpPopup/HelpPopup'
import BillingPopup from 'components/Popups/BillingPopup/BillingPopup'
import ProfilePopup from 'components/Popups/ProfilePopup/ProfilePopup'
import { DashboardMobileMenu } from 'components/Dashboard/DashboardMobileMenu'

import APP_DATA from 'utils/appData'
import { DASHBOARD_CONTENT_STATES, MEDIA_BREAKPOINTS, POPUPS_ID } from 'utils/constants'

import styles from './TalkerPage.module.scss'

import { profileApi, eventApi } from 'api'

import { setCalls, setRequests, logIn, setEvents } from 'store/actions'
import { LiveChatLink } from 'components/LiveChatLink'

const TalkerPage = (props) => {
  const {
    activePopupId,
    setActivePopupId,
    dashboardTabIndex,
    setDashboardTabIndex,
    setSentInvite,
    setCreatedClass,
    setIsNewUser,
    sentInvite,
    createdClass,
  } = useContext(UiContext)
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [contentState, setContentState] = useState(DASHBOARD_CONTENT_STATES.default)
  const [events, setEvents] = useState()
  const [timezone, setTimezone] = useState({ name: 'America/New_York', tag: 'en-US' })

  useEffect(() => {
    if (props?.events?.filter((e) => isFuture(new Date(e?.scheduled_at)))?.length > 0) {
      setCreatedClass(true)
    }
    if (props?.calls?.filter((e) => isFuture(new Date(e?.scheduled_at)))?.length > 0) {
      setSentInvite(true)
    }
    if (createdClass && sentInvite) {
      setIsNewUser(false)
    }
  }, [
    createdClass,
    props?.calls,
    props?.events,
    sentInvite,
    setCreatedClass,
    setIsNewUser,
    setSentInvite,
  ])

  useEffect(() => {
    eventApi.getProEvents().then((res) => {
      setEvents(res?.result.filter((e) => isPast(new Date(e.scheduled_at)) && e.title))
      props.setEvents(res?.result.filter((e) => e.title))
    })
  }, [])

  useEffect(() => {
    document.title = 'TalkPro | Dashboard'
  }, [])

  const windowSize = useWindowSize()

  const toggleSidebar = () => {
    setActivePopupId(null)
    setSidebarOpen(!isSidebarOpen)
  }

  const mobileTabsHandler = (index) => {
    setDashboardTabIndex(index)
  }

  const onClosePopup = async () => {
    setActivePopupId(null)

    await profileApi
      .getProfileInfo()
      .then((res) => props.logIn(res?.result?.profile))
      .then((e) => {
        window.location.reload()
      })
  }

  return (
    <div className={styles.dashboard}>
      {windowSize.width < MEDIA_BREAKPOINTS.lg && (
        <DashboardHeader handleClick={toggleSidebar} />
      )}
      <DashboardMobileMenu
        mobileTabsHandler={mobileTabsHandler}
        activeIndex={dashboardTabIndex}
      />
      {windowSize.width >= MEDIA_BREAKPOINTS.lg ? (
        <div className={`${styles.col} ${styles.sidebarCol}`}>
          <DashboardSidebar
            setDashboardContent={setContentState}
            dashboardContentCurrentState={contentState}
          />
        </div>
      ) : (
        <CSSTransition
          in={isSidebarOpen}
          timeout={{
            enter: 500,
            exit: 350,
          }}
          classNames={{
            enter: styles['popup-enter'],
            enterActive: styles['popup-enter-active'],
            exitActive: styles['popup-exit-active'],
            enterDone: styles['popup-enter-done'],
          }}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles['DashboardSidebar_wrap']}>
            <DashboardSidebar
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
              toggleClass={styles['popup-enter-done']}
              setDashboardContent={setContentState}
              dashboardContentCurrentState={contentState}
            />
          </div>
        </CSSTransition>
      )}

      <div className={`${styles.col} ${styles.infoCol}`}>
        {windowSize.width >= MEDIA_BREAKPOINTS.lg && (
          <DashboardAccount user={props.user} />
        )}
        {contentState === DASHBOARD_CONTENT_STATES.default && (
          <>
            <DashboardReminder />
            <MeetingsAndCalls />
          </>
        )}
      </div>

      {contentState === DASHBOARD_CONTENT_STATES.default && (
        <div className={`${styles.col} ${styles.talksCol}`}>
          {windowSize.width >= MEDIA_BREAKPOINTS.lg && (
            <DashboardRecentCalls toggleSidebar={toggleSidebar} />
          )}
          {windowSize.width < MEDIA_BREAKPOINTS.lg && dashboardTabIndex === 1 && (
            <DashboardRecentCalls toggleSidebar={toggleSidebar} />
          )}
        </div>
      )}
      <Popup
        isOpen={activePopupId === POPUPS_ID.createClass}
        title={
          window.localStorage.getItem('editEvent')
            ? APP_DATA.popups.editClass.title
            : APP_DATA.popups.createClass.title
        }
        description={
          window.localStorage.getItem('editEvent')
            ? APP_DATA.popups.editClass.description
            : APP_DATA.popups.createClass.description
        }
        centerContent
      >
        <CreateClassForm
          onSubmitCallback={onClosePopup}
          initialValues={
            props?.user?.live_classes_service ? props?.user?.live_classes_service : null
          }
          pro={props?.user}
          isOpen={activePopupId === POPUPS_ID.createClass}
          onSignup={false}
        />
      </Popup>
      <MeetingInfoPopup isOpen={activePopupId === POPUPS_ID.meetingInfo} />
      <Popup
        isOpen={activePopupId === POPUPS_ID.availability}
        title={APP_DATA.popups.availability.title}
        description={APP_DATA.popups.availability.description}
        centerContent
      >
        <AvailabilityForm
          onSubmitCallback={onClosePopup}
          setTimezone={setTimezone}
          initialValues={props?.user?.one_on_one_service}
        />
      </Popup>
      <AddVideoPopup
        isOpen={activePopupId === POPUPS_ID.addVideo}
        user={props?.user}
        title="YouTube Sync"
        description="Choose videos to display on booking page"
      />
      <SendInvitePopup
        isOpen={activePopupId === POPUPS_ID.sendInvite}
        user={props?.user}
      />
      <ClassesPopup isOpen={activePopupId === POPUPS_ID.classes} />
      <HelpPopup isOpen={activePopupId === POPUPS_ID.help} />
      <BillingPopup isOpen={activePopupId === POPUPS_ID.billing} />
      <ProfilePopup isOpen={activePopupId === POPUPS_ID.profile} />

      <LiveChatLink user={props?.user} />
    </div>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user, events, calls } }) => ({
  authError: error,
  user,
  isPro,
  events,
  calls,
})

const willMapDispatchToProps = {
  setRequests,
  setCalls,
  logIn,
  setEvents,
}

export default connect(mapStateToProps, willMapDispatchToProps)(TalkerPage)

import React, { useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { UiContext } from 'context/ui'
import { addDays, addMinutes, format, isFuture } from 'date-fns'
import ContentLoader from 'react-content-loader'

import aiImage from 'i/chatgpt.png'
import aiSound from 'i/sound.png'
import aiLock from 'i/lock.png'
import aiHipaa from 'i/hipaa.png'
import aiAssistant from 'i/ai_girl.png'
import startIcon from 'i/add.png'

import { DashboardMeeting } from '../DashboardMeeting'
import { DashboardUserInfo } from '../DashboardUserInfo'
import styles from './DashboardSchedule.module.scss'

import sessionTypes from 'utils/sessionTypes.json'

import { setCalls, setRequests } from 'store/actions'
import { POPUPS_ID } from 'utils/constants'

import { callApi, eventApi } from 'api'

const DashboardSchedule = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [sortedRequests, setSortedRequests] = useState()
  const [requestsLoading, setRequestsLoading] = useState(true)
  const [eventsLoading, setEventsLoading] = useState(true)
  const [combinedList, setCombinedList] = useState()
  const [isNewUser, setIsNewUser] = useState(false)
  const [requests, setRequests] = useState()
  const [events, setEvents] = useState()

  const { setActivePopupId, dashboardTabIndex } = useContext(UiContext)

  const showPopup = (popupId) => {
    setActivePopupId(popupId)
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    callApi?.getCalls().then((res) => {
      setRequests(res?.result)
    })
    eventApi?.getProEvents().then((res) => {
      setEvents(res?.result)
    })
  }, [])

  useEffect(() => {
    setCombinedList(
      events && requests
        ? [...requests, ...events]
        : requests
        ? requests
        : events
        ? events
        : []
    )
  }, [requests, events])

  useEffect(() => {
    setRequestsLoading(true)
    setEventsLoading(true)
    const requestsData = {}
    if (combinedList && combinedList?.length) {
      if (requests && events) {
        combinedList?.forEach((call) => {
          const date = format(new Date(call.scheduled_at), 'yyyy-MM-dd')

          if (
            !(
              isFuture(addMinutes(new Date(call.scheduled_at), 15)) &&
              !call.canceled &&
              !call.finished_at
            )
          ) {
            return
          }

          if (requestsData[date] && requestsData[date].length) {
            requestsData[date].push(call)
          } else {
            requestsData[date] = [call]
          }
        })
        setSortedRequests(requestsData)
        setRequestsLoading(false)
        setEventsLoading(false)
      }
    }
    if (requests && events) {
      setRequestsLoading(false)
      setEventsLoading(false)
    } else {
      setRequestsLoading(true)
      setEventsLoading(true)
      setSortedRequests(requestsData)
    }

    if (!requestsLoading && !eventsLoading) {
      if (
        combinedList?.filter(
          (e) => !e.canceled && !e.finished_at && isFuture(new Date(e.scheduled_at))
        )?.length > 0
      ) {
        setIsNewUser(false)
      } else {
        setIsNewUser(true)
      }
    }
  }, [combinedList, events, props.calls, props.events, requests])

  useEffect(() => {
    console.log(sortedRequests)
  }, [sortedRequests])

  return (
    <div className={styles.dashboardInfo}>
      {isNewUser && (
        <div class="dashboard_ai_assistant">
          <div class="ai_item ai_image">
            <div class="image_container">
              <img src={aiAssistant} class="image_assistant" />
            </div>
          </div>
          <div class="ai_item ai_content">
            <div class="ai_heading">
              Meet Emma, <br />
              Your real AI assistant
            </div>
            <div class="content_ul">
              <div class="content_li">
                <div class="li_icon">
                  <img src={aiImage} alt="" class="icon_image" />
                </div>
                <div class="li_title">
                  <p>Meeting notes taken by Emma AI</p>
                </div>
              </div>
              <div class="content_li">
                <div class="li_icon">
                  <img src={aiSound} alt="" class="icon_image" />
                </div>
                <div class="li_title">
                  <p>Ask Emma on your call to take note</p>
                </div>
              </div>
              <div class="content_li">
                <div class="li_icon">
                  <img src={aiLock} alt="" class="icon_image" />
                </div>
                <div class="li_title">
                  <p>End-to-end encrypted meetings</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ai_item ai_logo">
            <div class="image_container">
              <img src={aiHipaa} class="image_logo" />
            </div>
          </div>
        </div>
      )}

      {dashboardTabIndex === 0 && (
        <DashboardUserInfo
          isNewUser={isNewUser}
          info={combinedList?.length > 0}
          loading={!requestsLoading && !eventsLoading}
        />
      )}

      {!isNewUser && (
        <div className={styles.dashboardSchedule}>
          {requestsLoading && eventsLoading && (
            <>
              <ContentLoader className={styles.dashboardScheduleItem} />
              <ContentLoader className={styles.dashboardScheduleItem} />
            </>
          )}
          {!requestsLoading &&
          !eventsLoading &&
          sortedRequests &&
          !isNewUser &&
          Object.keys(sortedRequests).length
            ? Object.keys(sortedRequests)
                .sort((a, b) => new Date(a) - new Date(b))
                .map((date, index) => {
                  {
                    /*const today = format(new Date(), 'yyyy-mm-dd');
                  const eventDate = format(addDays(new Date(date), 1), 'yyyy-mm-dd');
                  const eventPrintDate = '';
                  if( today == eventDate ){
                    eventPrintDate = 'Today';
                  }else{
                    eventPrintDate = format(addDays(new Date(date), 1), 'EE d MMM');
                  } */
                  }

                  const isOnlyInstantFree = sortedRequests[date].every(item => item.payment_id === "instant_free");

                  if (isOnlyInstantFree) return null;
                  
                  return (
                    <div className={styles.dashboardScheduleItem} key={index}>
                      <div className={styles.dashboardScheduleHeading}>
                        <div className={styles.dashboardScheduleDate}>
                          {/* {format(addDays(new Date(date), 1), 'EEEE, MMMM d yyyy')} */}
                          {format(addDays(new Date(date), 1), 'EE d MMM')}
                        </div>
                      </div>
                      <div className={styles.dashboardScheduleMeetings}>
                        {sortedRequests[date]
                          ?.sort(
                            (a, b) => new Date(a.scheduled_at) - new Date(b.scheduled_at)
                          )
                          .map((call, index) => {
                            const attendes = call?.attendes
                              ?.split(',')
                              ?.filter(
                                (e) =>
                                  e !== ' ' && e !== '' && e !== call?.pro?.user?.email
                              )

                            const name = call?.title
                              ? call?.title
                              : call?.client?.user?.name
                            const cost = call?.private
                              ? attendes.length > 1
                                ? `${attendes?.length} Invited`
                                : ''
                              : call?.rate || call.entry_fee === 0
                              ? ''
                                  .toString()
                                  .concat('$')
                                  .concat(
                                    parseInt(call?.rate || call.entry_fee)
                                      .toFixed(0)
                                      .toString()
                                  )
                              : ''
                            const descr = call?.entry_fee
                              ? call?.private
                                ? ''
                                : `${attendes?.length} of ${call?.max_participants} confirmed`
                              : ''

                            const avatar = call?.title
                              ? call?.cover_image
                                ? call?.cover_image
                                : null
                              : call?.client?.photo

                            const scheduledAt = format(new Date(call?.scheduled_at), 'p')
                            const sessionType = !call.title
                              ? sessionTypes[`${props?.user?.category}`]
                              : ''

                            if (call.payment_id === "instant_free") return null;

                            return (
                              <div
                                className={
                                  call?.entry_fee
                                    ? styles.dashboardScheduleEventItem
                                    : styles.dashboardScheduleEventItem
                                }
                                key={`schedule_item_${index}`}
                              >
                                <DashboardMeeting
                                  client={call?.client}
                                  name={name}
                                  avatar={avatar}
                                  descr={descr}
                                  cost={cost}
                                  users={attendes}
                                  time={scheduledAt}
                                  sessionType={sessionType}
                                  callInfo={call}
                                />
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  )
                })
            : !requestsLoading &&
              !eventsLoading &&
              isNewUser && (
                <></>
                // <DashboardUserInfo
                //   isNewUser={true}
                //   info={combinedList?.length > 0}
                //   loading={!requestsLoading && !eventsLoading}
                // />
              )}
        </div>
      )}

      {isNewUser && (
        <div className={styles.dashboardSchedule}>
          <div className={styles.dashboardScheduleItem}>
            <div className={styles.dashboardScheduleHeading} test>
              <div className={styles.dashboardScheduleDate}>
                {format(new Date(), 'EE d MMM')}
              </div>
            </div>
            <div>
              <div
                class="dashboardStartMeeting"
                onClick={() => showPopup(POPUPS_ID.sendInvite)}
              >
                <div class="dashboardMeetingWrap">
                  <div class="startIconWrap">
                    <img src={startIcon} class="start_icon" />
                  </div>
                  <div class="startContentWrap">
                    <p>Start meeting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user } }) => ({
  authError: error,
  user,
  isPro,
})

const willMapDispatchToProps = {
  setRequests,
  setCalls,
}

export default connect(mapStateToProps, willMapDispatchToProps)(DashboardSchedule)

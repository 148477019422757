import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  addMinutes,
  isFuture,
  isPast,
  subMinutes,
  formatDistanceToNow,
  differenceInMinutes,
  addHours,
} from 'date-fns'

import styles from './DashboardReminder.module.scss'

import { strikesApi } from 'api'

const DashboardReminder = ({ calls, events }) => {
  const history = useHistory()
  const [combinedList, setCombinedList] = useState()

  useEffect(() => {
    if (calls && events) {
      setCombinedList([...calls, ...events])?.sort(
        (a, b) => new Date(a.scheduled_at) - new Date(b.scheduled_at)
      )
    }
  }, [events, calls])

  const checkTimeHasCome = (call) => {
    return (
      isFuture(addMinutes(new Date(call?.scheduled_at), 15)) &&
      isPast(subMinutes(new Date(call?.scheduled_at), 15))
    )
  }

  const latestCall = combinedList?.filter((call) => {
    return (
      (!call.canceled &&
        isFuture(addMinutes(new Date(call?.scheduled_at), 15)) &&
        isPast(subMinutes(new Date(call?.scheduled_at), 15)) &&
        !call.declined &&
        !call.finished_at) ||
      (call.started_at &&
        !call.finished_at &&
        isFuture(addHours(new Date(call?.scheduled_at), 12)) &&
        isPast(subMinutes(new Date(call?.scheduled_at), 15)))
    )
  })[0]

  const handleClickBtn = () => {
    if (latestCall.payment_id === 'instant_free') {
      history.push(`/${latestCall?.pro?.channel_name}/room`)
      return
    }

    if (checkTimeHasCome(latestCall) && latestCall?.call_request) {
      history.push(`/talk/${latestCall?.call_request}`, latestCall)
    } else if (checkTimeHasCome(latestCall) && latestCall?.title) {
      history.push(`/event/${latestCall?.id}`, latestCall)
    }
  }

  useEffect(() => {
    if (isPast(new Date(latestCall?.scheduled_at))) {
      strikesApi.sendReport({
        user: latestCall?.pro,
        client: latestCall?.client,
        pro: latestCall?.pro,
        title: 'Late for call',
        description: 'More than 15 minutes late for call',
        call_id: latestCall?.id,
      })
    }
  }, [latestCall])

  const companionName = latestCall?.title
    ? latestCall?.pro?.user?.name
    : latestCall?.client?.user?.name
  const scheduledAt = latestCall?.scheduled_at

  return (
    <>
      {companionName && scheduledAt && (
        <div className={styles.dashboardReminder}>
          <div className={styles.dashboardReminderIn}>
            {isPast(new Date(latestCall?.scheduled_at), 15) ? (
              <>
                <div className={styles.dashboardReminderContent}>
                  {latestCall?.title
                    ? `Your class started ${' '}`
                    : `Call with ${companionName} started ${' '}`}
                  {formatDistanceToNow(new Date(scheduledAt))} ago
                </div>
                {differenceInMinutes(new Date(scheduledAt), new Date(), 'ceil') < 15 && (
                  <button
                    className={styles.dashboardReminderLink}
                    type="button"
                    onClick={handleClickBtn}
                  >
                    Join Call
                  </button>
                )}
              </>
            ) : (
              <>
                <div className={styles.dashboardReminderContent}>
                  Next call starts in {formatDistanceToNow(new Date(scheduledAt))}
                </div>
                {differenceInMinutes(new Date(scheduledAt), new Date(), 'ceil') < 15 && (
                  <button
                    className={styles.dashboardReminderLink}
                    type="button"
                    onClick={handleClickBtn}
                  >
                    Waiting room
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user, calls, events } }) => ({
  authError: error,
  calls,
  user,
  isPro,
  events,
})

export default connect(mapStateToProps)(DashboardReminder)

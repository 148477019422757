import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import styles from './FormInputWithLabel.module.scss'

import { ReactComponent as CloseIcon } from 'i/icons/close.svg'

const FormInputWithLabel = ({
  img,
  icon,
  label,
  children,
  className,
  colorV2,
  iconPosition,
  textarea,
  select,
}) => {
  const $block = useRef(null)
  const elRef = useRef(null)
  const [isActive, setActive] = useState(false)

  const isTouchDevice = () => {
    return (
      'ontouchstart' in window ||
      window.navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0
    )
  }

  const inputHandler = (input) => {
    if (input.value.length) {
      setActive(true)
    }

    input.addEventListener('click', () => {
      setTimeout(() => {
        setActive(true)
      }, 200)
    })

    input.addEventListener('focus', () => {
      setActive(true)
    })

    const observer = new MutationObserver(function (mutationsList) {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
          if (input.value.length) {
            setActive(true)
          }
        }
      }
    })

    observer.observe(input, { attributes: true })

    input.addEventListener('blur', () => {
      setTimeout(() => {
        const val = input.value
        if (!val.length) {
          setActive(false)
        }
      }, 150)
    })
  }

  const touchBlurHandler = (input) => {
    document.body.addEventListener('click', (e) => {
      if (input.value.length === 0) {
        setActive(false)
      }
    })
  }

  const selectHandler = (select) => {
    if (select.value.length) {
      setActive(true)
    }

    select.addEventListener('click', (e) => {
      setActive(true)
    })

    select.addEventListener('blur', (e) => {
      if (!select.value.length) {
        setActive(false)
      }
    })
  }

  useEffect(() => {
    const children = elRef.current.children
    let haveInput = false

    const availableTags = ['INPUT', 'TEXTAREA']

    Array.from(children).forEach((child, index) => {
      const tag = child.tagName

      if (availableTags.includes(tag)) {
        inputHandler(child)
        haveInput = true
      }

      if (tag === 'SELECT') {
        selectHandler(child)
      }
    })

    if (!haveInput) {
      const input = children[0].querySelector(availableTags)

      if (input) {
        inputHandler(input)
        if (isTouchDevice()) {
          touchBlurHandler(input)
        }
      }
    }
  }, [])

  return (
    <div className={styles.wrap}>
      <label
        className={classNames(styles.block, {
          [styles.blockActive]: isActive,
          [styles.blockIcon]: icon,
          [styles.labelColorV2]: colorV2,
          [styles.iconOffset]: iconPosition,
          [styles.textareaMod]: textarea,
          [styles.selectMod]: select,
        })}
        ref={$block}
      >
        <div className={styles.children} ref={elRef}>
          {children}
        </div>
        <div className={`${styles.label} ${className}`}>{label}</div>
        {/* <input className={styles.input} type={type} ref={$input} /> */}
        {icon && (
          <div
            className={classNames(styles.icon, {
              [styles.iconPosV2]: iconPosition,
            })}
          >
            {icon}
          </div>
        )}
        {img && (
          <div
            className={classNames(styles.icon, {
              [styles.iconPosV2]: iconPosition,
            })}
          >
            <img src={img} alt="social icon" />
          </div>
        )}
      </label>
    </div>
  )
}

export default FormInputWithLabel
